import React from "react"
import CategoryWrapper from "../../components/CategoryWrapper"

function MenShortsPage() {
  return (
    <CategoryWrapper
      seoTitle="Sport"
      gender="herre"
      category="sport"
    />
  )
}

export default MenShortsPage
